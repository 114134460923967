interface Config {
  production: String
  baseUrl: String
  tenant: String
  version: String
  tokenUrl: String
  toolVersion: String
  platform: String
  mpKey: string
  reCaptchaKey: String
}

class ConfigService {
  public config: Config | null

  constructor() {
    this.config = null
  }

  async init() {
    const res = await fetch('/assets/config/config.json')
    const config = await res.json()

    this.config = config
  }

  getConfig() {
    return this.config
  }
}

export default new ConfigService()
